var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.$t("lbl_detail_x", {
              x: _vm.$t("lbl_goods_receipt_price")
            })
          }
        },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                "label-align": "left",
                "wrapper-col": { span: 12 },
                "label-col": { span: 9 }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_receive_number") } },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.receiveNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "receiveNumber", $$v)
                              },
                              expression: "store.detail.receiveNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_purchase_order_number"),
                            prop: "purchaseOrderNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.purchaseOrderNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detail,
                                  "purchaseOrderNumber",
                                  $$v
                                )
                              },
                              expression: "store.detail.purchaseOrderNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_supplier_name") } },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.supplierName,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "supplierName", $$v)
                              },
                              expression: "store.detail.supplierName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            prop: "branchWarehouse"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.branchWarehouse,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detail,
                                  "branchWarehouse",
                                  $$v
                                )
                              },
                              expression: "store.detail.branchWarehouse"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_receive_date"),
                            prop: "receiveDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                              "show-time": "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.form.receiveDate,
                              callback: function($$v) {
                                _vm.$set(_vm.store.form, "receiveDate", $$v)
                              },
                              expression: "store.form.receiveDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_term_of_payment"),
                            prop: "termOfPayment"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.termOfPayment,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "termOfPayment", $$v)
                              },
                              expression: "store.detail.termOfPayment"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "currencyCode"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.currencyCode,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "currencyCode", $$v)
                              },
                              expression: "store.detail.currencyCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.store.detail.currencyCode !== "IDR",
                              expression: "store.detail.currencyCode !== 'IDR'"
                            }
                          ],
                          attrs: {
                            label: _vm.$t("lbl_currency_rate"),
                            prop: "currencyRate"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              parser: _vm.reverseFormatNumber,
                              formatter: _vm.formatterNumber,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detail.currencyRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detail,
                                  "currencyRate",
                                  _vm._n($$v)
                                )
                              },
                              expression: "store.detail.currencyRate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_calculation"),
                            prop: "taxType",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.taxType,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "taxType", $$v)
                              },
                              expression: "store.detail.taxType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_supplier_do_number"),
                            prop: "supplierDeliveryOrderNo",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.supplierDeliveryOrderNo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detail,
                                  "supplierDeliveryOrderNo",
                                  $$v
                                )
                              },
                              expression: "store.detail.supplierDeliveryOrderNo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_supplier_bill_address"),
                            prop: "supplierBillToAddress",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.supplierBillToAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detail,
                                  "supplierBillToAddress",
                                  $$v
                                )
                              },
                              expression: "store.detail.supplierBillToAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_journal_number"),
                            "label-align": "right"
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "generaljournal.detail",
                                  params: {
                                    id: _vm.store.detail.journalId
                                  }
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                { staticClass: "p-0", attrs: { type: "link" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.store.detail.journalNo) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_created_by"),
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.createdBy,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "createdBy", $$v)
                              },
                              expression: "store.detail.createdBy"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "label-align": "right",
                            label: _vm.$t("lbl_status")
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.status,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "status", $$v)
                              },
                              expression: "store.detail.status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_description"),
                            prop: "description",
                            "label-align": "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: {
                                      value: _vm.store.detail.description
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.description,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "description", $$v)
                              },
                              expression: "store.detail.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_luxury_goods"),
                            prop: "isLuxuryGoods",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-switch", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detail.isLuxuryGoods,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detail, "isLuxuryGoods", $$v)
                              },
                              expression: "store.detail.isLuxuryGoods"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              "data-source": _vm.store.detail.receiveItems,
              columns: _vm.columns,
              size: "small",
              "row-key": "id",
              pagination: {
                showSizeChanger: true,
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                }
              },
              scroll: { x: "calc(100% + 900px)" }
            },
            scopedSlots: _vm._u([
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                }
              },
              {
                key: "currency",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(_vm._s(_vm._f("currency")(text)))
                  ])
                }
              },
              {
                key: "qty",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
                }
              },
              {
                key: "boolean",
                fn: function(text) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("DisplayBoolean", {
                        attrs: { value: text, mode: "yes" }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "title",
                fn: function() {
                  return [_c("span", [_vm._v(_vm._s(_vm.$t("lbl_product")))])]
                },
                proxy: true
              },
              {
                key: "notes",
                fn: function(text, row) {
                  return [
                    _c(
                      "a-badge",
                      { attrs: { count: row.itemAssets.length } },
                      [
                        _c("a-button", {
                          attrs: { icon: "bars" },
                          on: {
                            click: function($event) {
                              return _vm.showModal(row)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("a-divider"),
          _c("DisplayTotal"),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "mt-3 text-right" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "50%",
            title: _vm.$tc("lbl_asset_item", _vm.modal.data.length)
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.modal.toggle()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modal.visible,
            callback: function($$v) {
              _vm.$set(_vm.modal, "visible", $$v)
            },
            expression: "modal.visible"
          }
        },
        [
          _c("a-table", {
            attrs: {
              "data-source": _vm.modal.data,
              columns: _vm.columnsAsset,
              "row-key": function(r, i) {
                return i
              },
              pagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                }
              },
              size: "small"
            },
            scopedSlots: _vm._u([
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(text || "-") + " ")
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }