var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-button", {
        staticClass: "bottompage",
        attrs: { type: _vm.type, shape: _vm.shape, icon: _vm.icon },
        on: {
          click: function($event) {
            return _vm.onClick()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }